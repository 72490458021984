<template>
  <!-- BEGIN: Content-->
  <div class="body-login">
    <div class="content-overlay" />
    <div class="content-wrapper">
      <div class="content-header row">
        <!-- test 1 -->
        <b-modal
          id="collapseModals"
          v-model="showModal"
          title="PERSONAL DATA PROTECTION CONTRACT"
          :no-close-on-backdrop="true"
          :no-close-on-esc="true"
          :hide-header-close="true"
          @ok="handleOk"
          @cancel="handleCancel"
        >
          <h4>Please, read and press the OK button</h4>
          <div
            v-if="showableText"
            class="legal__content__modal"
            @scroll="onScroll"
            v-html="showableText"
          />
        </b-modal>
      </div>
      <div class="content-body">
        <div class="auth-wrapper auth-cover">
          <div class="auth-inner row m-0">
            <!-- Brand logo-->
            <a
              class="brand-logo"
              href="https://www.icrea.cat"
            />

            <!-- /Brand logo-->
            <!-- Left Text-->
            <div
              class="d-none d-lg-flex col-lg-8 align-items-center p-5"
              :style="{'background': 'url(' + require('@/assets/images/illustration/bg-login.svg') + ') center center no-repeat', 'background-size': 'cover'}"
            >
              <div
                class="
                  w-100
                  d-lg-flex
                  align-items-center
                  justify-content-center
                  flex-column
                  px-5
                "
              >
                <div class="mb-4">
                  <img
                    class="img-fluid"
                    :src="require('@/assets/images/logo/logo-icrea-white-login.svg')"
                    alt="ICREA"
                  >
                </div>
                <div class="quote">
                  <h1>
                    <em>"You never fail until you stop trying"</em>
                  </h1>
                  <hr>
                  <p>Albert Einstein</p>
                </div>
              </div>
            </div>
            <!-- /Left Text-->
            <!-- Login-->
            <div class="d-flex col-lg-4 align-items-center auth-bg px-2 p-lg-5">
              <div class="col-12 col-sm-8 col-md-6 col-lg-12 px-xl-2 mx-auto">
                <h2 class="card-title fw-bold mb-0">
                  YOU ARE IN THE ICREA INTRANET:
                </h2>
                <p><strong class="text-dark">for applicants / researchers / evaluators / universities</strong></p>
                <!-- <div class="alert alert-warning">
                  <div class="alert-body text-dark">
                    To go to the <strong>applicants area</strong> <a
                      @click="$router.push({ name: 'front.login' })"
                    ><u><strong>please click here</strong>.</u></a>
                  </div>
                </div> -->
                <div v-if="!showButtons">
                  <p class="card-text mb-2">
                    If you want to have access to the general intranet, please introduce your credentials:
                  </p>
                  <div class="mb-1">
                    <label
                      class="form-label"
                      for="login-email"
                    >Email</label>
                    <input
                      id="login-email"
                      v-model="email"
                      class="form-control"
                      type="text"
                      name="login-email"
                      placeholder="john@example.com"
                      aria-describedby="login-email"
                      autofocus=""
                      tabindex="1"
                    >
                  </div>
                  <div class="mb-1">
                    <div class="d-flex justify-content-between">
                      <label
                        class="form-label"
                        for="login-password"
                      >Password</label><a
                        @click="$router.push({ name: 'reset-password-intranet' })"
                      ><small>Forgot Password?</small></a>
                    </div>
                    <div
                      class="input-group input-group-merge form-password-toggle"
                    >
                      <input
                        id="login-password"
                        v-model="password"
                        class="form-control form-control-merge"
                        :type="type"
                        name="login-password"
                        placeholder="············"
                        aria-describedby="login-password"
                        tabindex="2"
                      ><span
                        class="input-group-text cursor-pointer"
                        @click="showPassword"
                      ><i data-feather="eye" /></span>
                    </div>
                  </div>
                  <div class="mb-1">
                    <div class="form-check">
                      <input
                        id="remember-me"
                        class="form-check-input"
                        type="checkbox"
                        tabindex="3"
                      >
                      <label
                        class="form-check-label"
                        for="remember-me"
                      >
                        Remember Me</label>
                    </div>
                  </div>
                  <!-- <button class="btn btn-primary w-100" tabindex="4">Sign in</button> -->
                  <b-button
                    v-if="!loading"
                    type="submit"
                    variant="primary"
                    block
                    @click="validationForm"
                  >
                    Sign in
                  </b-button>

                  <b-button
                    v-else
                    type="submit"
                    disabled
                  >
                    <div class="loading">
                      <div class="effect-1 effects" />
                      <div class="effect-2 effects" />
                      <div class="effect-3 effects" />
                    </div>
                  </b-button>
                  <!-- <a
                      href="../senior-call/index.php"
                      class="btn btn-primary w-100"
                      tabindex="4"
                    >Sign in</a> -->
                  <p class="text-center mt-2">
                    <span>Problems login to your account? </span><a
                      @click="$router.push({ name: 'reset-password-intranet' })"
                    > Reset your password</a>
                  </p>
                </div>
                <div v-if="showButtons && user.id">
                  <p class="card-text mb-2">
                    Logged successfully as <strong>{{ user.true_name }}</strong>. With which profile would you log in?
                  </p>

                  <b-button
                    v-if="user.roles.includes('publication-admin')"
                    variant="primary"
                    block
                    target="_blank"
                    :href="$router.resolve({ name: 'researcher.publications.index', query: { actAs: $route.query.actAs } }).href"
                  >
                    Admin publications
                  </b-button>

                  <b-button
                    v-if="user.roles.includes('super-admin')"
                    class="me-1"
                    variant="primary"
                    block
                    target="_blank"
                    :href="$router.resolve({ name: 'admin.calls.senior.index', query: { actAs: $route.query.actAs } }).href"
                  >
                    Super admin
                  </b-button>

                  <b-button
                    v-if="user.roles.includes('researcher') && user.roles.includes('evaluator')"
                    class="me-1"
                    variant="primary"
                    block
                    target="_blank"
                    :href="$router.resolve({ name: 'researcher.dashboard', query: { actAs: $route.query.actAs } }).href"
                  >
                    Researcher + evaluator
                  </b-button>

                  <b-button
                    v-if="user.roles.includes('researcher') && !user.roles.includes('evaluator')"
                    class="me-1"
                    variant="primary"
                    block
                    target="_blank"
                    :href="$router.resolve({ name: 'researcher.dashboard', query: { actAs: $route.query.actAs } }).href"
                  >
                    Researcher
                  </b-button>

                  <b-button
                    v-if="!user.roles.includes('researcher') && user.roles.includes('evaluator')"
                    class="me-1"
                    variant="primary"
                    block
                    target="_blank"
                    :href="$router.resolve({ name: 'evaluator.calls.evaluations.profile', query: { actAs: $route.query.actAs } }).href"
                  >
                    Evaluator
                  </b-button>

                  <b-button
                    v-if="!user.roles.includes('researcher') && user.roles.includes('pc-member')"
                    class="me-1"
                    variant="primary"
                    block
                    target="_blank"
                    :href="$router.resolve({ name: 'evaluator.calls.evaluations.profile', query: { actAs: $route.query.actAs } }).href"
                  >
                    PC Member
                  </b-button>

                  <b-button
                    v-if="user.roles.includes('awarded')"
                    class="me-1"
                    variant="primary"
                    block
                    target="_blank"
                    :href="$router.resolve({ name: 'awarded.profile', query: { actAs: $route.query.actAs } }).href"
                  >
                    Awarded
                  </b-button>

                  <b-button
                    v-if="user.roles.includes('ondeuev')"
                    class="me-1"
                    variant="primary"
                    block
                    target="_blank"
                    :href="$router.resolve({ name: 'memoir-exports.index', query: { actAs: $route.query.actAs } }).href"
                  >
                    Ondeuev
                  </b-button>

                  <b-button
                    v-if="user.roles.includes('university')"
                    class="me-1"
                    variant="primary"
                    block
                    target="_blank"
                    :href="$router.resolve({ name: 'university.dashboard', query: { actAs: $route.query.actAs } }).href"
                  >
                    Host
                  </b-button>

                  <b-button
                    v-if="user.roles.includes('cae')"
                    class="me-1"
                    variant="primary"
                    block
                    target="_blank"
                    :href="$router.resolve({ name: 'cae.index', query: { actAs: $route.query.actAs } }).href"
                  >
                    CAE
                  </b-button>

                  <b-button
                    v-if="user.roles.includes('applicant')"
                    class="me-1"
                    variant="primary"
                    block
                    target="_blank"
                    :href="$router.resolve({ name: 'front.senior-call', query: { actAs: $route.query.actAs } }).href"
                  >
                    Applicant
                  </b-button>
                </div>
              </div>
            </div>
            <!-- /Login-->
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Content-->
</template>

<script>
import { BButton, BModal } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    BButton,
    BModal,
  },
  data() {
    return {
      status: '',
      password: '',
      email: '',
      type: 'password',
      loading: false,
      reading_time: 0,
      okDisabled: true,
      showModal: false,
      showButtons: false,
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/admin',
      hasLegal: 'users/hasLegal',
      aux: 'auth/aux',
      showableText: 'auth/showableText',
    }),
  },
  async mounted() {
    feather.replace({
      width: 14,
      height: 14,
    })
    this.showModal = this.hasLegal
    if (this.user.id) {
      if (this.user.roles.length === 1) {
        if (this.user.roles.includes('publication-admin')) {
          this.$router.push({ name: 'researcher.publications.index' })
        } else if (this.user.roles.includes('super-admin')) {
          this.$router.push({ name: 'admin.calls.senior.index' })
        } else if (this.user.roles.includes('researcher')) {
          this.$router.push({ name: 'researcher.dashboard' })
        } else if (this.user.roles.includes('evaluator')) {
          this.$router.push({ name: 'evaluator.calls.evaluations.profile' })
        } else if (this.user.roles.includes('awarded')) {
          this.$router.push({ name: 'awarded.profile' })
        } else if (this.user.roles.includes('ondeuev')) {
          this.$router.push({ name: 'memoir-exports.index' })
        } else if (this.user.roles.includes('university')) {
          this.$router.push({ name: 'university.dashboard' })
        } else if (this.user.roles.includes('applicant')) {
          this.$router.push({ name: 'front.senior-call' })
        } else if (this.user.roles.includes('cae')) {
          this.$router.push({ name: 'cae.index' })
        } else {
          this.$router.push({ name: 'researcher.dashboard' })
        }
      } else {
        this.showButtons = true
      }
    } else {
      this.showModal = false
    }
  },
  methods: {
    loadingMode(status = true) {
      this.loading = status
    },
    async validationForm() {
      this.loadingMode()

      if (this.email && this.password) {
        this.$store.dispatch('auth/backLogin', {
          email: this.email,
          password: this.password,
          title: 'Personal data protection contract (evaluators)',
        }).then(async () => {
          if (Object.entries(this.user).length > 0) {
            if (this.user.roles.length === 1) {
              if (this.user.roles.includes('publication-admin')) {
                this.$router.push({ name: 'researcher.publications.index' })
              } else if (this.user.roles.includes('super-admin')) {
                this.$router.push({ name: 'admin.calls.senior.index' })
              } else if (this.user.roles.includes('researcher')) {
                this.$router.push({ name: 'researcher.dashboard' })
              } else if (this.user.roles.includes('evaluator')) {
                this.$router.push({ name: 'evaluator.calls.evaluations.profile' })
              } else if (this.user.roles.includes('awarded')) {
                this.$router.push({ name: 'awarded.profile' })
              } else if (this.user.roles.includes('ondeuev')) {
                this.$router.push({ name: 'memoir-exports.index' })
              } else if (this.user.roles.includes('university')) {
                this.$router.push({ name: 'university.dashboard' })
              } else if (this.user.roles.includes('applicant')) {
                this.$router.push({ name: 'front.senior-call' })
              } else if (this.user.roles.includes('cae')) {
                this.$router.push({ name: 'cae.index' })
              } else {
                this.$router.push({ name: 'researcher.dashboard' })
              }
            } else {
              this.showButtons = true
            }

            // if (this.user.roles.includes('publication-admin')) {
            //   this.$router.push({ name: 'researcher.publications.index' })
            // } else if (this.user.roles.includes('super-admin')) {
            //   this.$router.push({ name: 'admin.calls.senior.index' })
            // } else if (this.user.roles.includes('university')) {
            //   this.$router.push({ name: 'university.dashboard' })
            // } else if (this.user.roles.includes('ondeuev')) {
            //   this.$router.push({ name: 'memoir-exports.index' })
            // } else if (!this.user.roles.includes('super-admin')) {
            //   this.$router.push({ name: 'researcher.dashboard' })
            // }
          } else if (this.aux.user && this.aux.user.roles_all.some(e => e.evaluator === true)) {
            await this.$store.dispatch('users/checkEvaluatorData')
            this.showModal = this.hasLegal
            if (!this.hasLegal) {
              this.$store.dispatch('auth/setUser', this.aux).then(() => {
                if (this.user.roles.length === 1) {
                  if (this.user.roles.includes('researcher')) {
                    this.$router.push({ name: 'researcher.dashboard' })
                  } else {
                    this.$router.push({ name: 'evaluator.calls.evaluations.profile' })
                  }
                } else {
                  this.showButtons = true
                }
              })
            } else {
              setInterval(() => {
                this.reading_time += 0.1
              }, 100)
            }
          }
        }).catch(() => {
          this.loadingMode(false)
        })
      } else {
        this.loadingMode(false)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Falten camps!',
            icon: 'ErrorIcon',
            variant: 'error',
          },
        })
      }
    },
    showPassword() {
      if (this.type === 'password') {
        this.type = 'text'
      } else {
        this.type = 'password'
      }
    },
    onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (scrollTop + clientHeight >= scrollHeight) {
        this.okDisabled = false
      }
    },
    async handleOk() {
      await this.$store.dispatch('users/saveEvaluatorData', { reading_time: this.reading_time }).then(async () => {
        await this.$store.dispatch('auth/setUser', this.aux).then(() => {
          this.$router.push({ name: 'evaluator.calls.evaluations.profile' })
        })
        this.$toast.success('Saved legal data')
      })
    },
    async handleCancel() {
      await this.$store.dispatch('auth/adminLogout')
      this.loadingMode(false)
    },
  },
}
</script>
